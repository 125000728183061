import { ReactElement } from "react";
import styles from "./styles.module.scss";
import { TButton } from "@page-builder/ts/generated";

export default function Button({
    props = {},
}: {
    props?: TButton["props"];
}): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return props?.buttonAction === "scroll" ? (
        <button onClick={() => scrollToTop()} className={styles.button}>
            {props?.buttonText}
        </button>
    ) : (
        <button className={styles.button}>
            <a
                href={props?.buttonAction?.href}
                target={props?.buttonAction?.target}
            >
                {props?.buttonText}
            </a>
        </button>
    );
}
